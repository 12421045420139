












































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import gql from 'graphql-tag';
import ObjectCard from '@/components/content/ObjectCard.vue';
import ObjectDetail from '@/components/content/ObjectDetail.vue';

interface Object {
  id: string;
  slug: string;
  typeHandle: string;
  title: string;
  plz: string;
  ortschaft: string;
  heroObject: boolean;
  price: number;
  quadratmeterPreis: boolean;
  rooms: number;
  livingroom: number;
  configuration: {id: number, title: string}[];
  generalInformation: [{
    availableDate: {
      id: number,
      aufNachfrage: boolean,
      availability: string,
      __typename: string,
    }[]
  }];
}

interface ObjectToolbarStyle {
  marginTop: string;
}

interface ObjectScrollContentStyle {
  height: string;
}

@Component({
  components: {
    ObjectCard,
    ObjectDetail,
  },
})
export default class ObjectsContent extends Vue {
  private toolbarHeight: number = 56;

  private limit: number = 12;

  private offset: number = 0;

  private currentScrollPosition: number = 0;

  public objectToolbarVisible: boolean = true;

  @Prop({ type: Object, default: {} })
  private filter;

  public objectDetailDataLoaded: boolean = false;

  public objects: Object[] = [];

  public selectedObjectTitle: string = '';

  public objectsFiltered: Object[] = [];

  public isLoading: boolean = false;

  public selectedObjectId: number = -1;

  private scrollContentDiv: HTMLDivElement | null = null;

  public preloadSelectedObjectId: number = -1;

  public get objectToolbarStyle(): ObjectToolbarStyle {
    const toolbarShouldHide = !this.objectToolbarVisible && this.$vuetify.breakpoint.xs;
    const marginTop: number = toolbarShouldHide ? -this.toolbarHeight : 0;

    return {
      marginTop: `${marginTop}px`,
    };
  }

  public get objectScrollContentStyle(): ObjectScrollContentStyle {
    let height: number = !this.objectToolbarVisible ? 0 : this.toolbarHeight;
    if (!this.$vuetify.breakpoint.xs) {
      height = 100;
    }

    return {
      height: `calc(100vh - ${height}px)`,
    };
  }

  @Prop({ type: String })
  public type!: string;

  mounted() {
    this.loadObjectsByType();
    const scrollContentDiv = document.querySelector<HTMLDivElement>('.objects__scroll-content');
    if (scrollContentDiv != null) {
      this.scrollContentDiv = scrollContentDiv;
      scrollContentDiv.addEventListener('scroll', (event) => {
        this.handleContainerScroll(event);
      }, { passive: true });

      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      scrollContentDiv.addEventListener('resize', () => {
        // We execute the same script as before
        vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh + 100}px`);
      });
    }
  }

  private price: number = 0;

  public set isDialogOpen(objectId) {
    this.selectedObjectId = -1;
    if (!objectId) {
      this.$router.push({ name: this.type.toLowerCase() });
    }
  }

  public get isDialogOpen(): boolean {
    const locked = this.selectedObjectId > 0;
    // this.lockParentScroll(locked && this.objectDetailDataLoaded);
    return locked && this.objectDetailDataLoaded;
  }

  public lockParentScroll(lock: boolean = true) {
    let position = 'static';
    let width = 'auto';
    let top = 'auto';
    const scrollBefore = parseInt(document.getElementsByTagName('html')[0].style.top, 10);
    if (lock) {
      if (window.scrollY === 0 && scrollBefore !== 0) {
        top = `${scrollBefore}px`;
      } else {
        top = `-${window.scrollY}px`;
      }
      width = '100%';
      position = 'fixed';
    }

    if (top === 'auto' && this.scrollContentDiv != null) {
      document.getElementsByTagName('html')[0].scrollTo({
        top: scrollBefore,
        left: 0,
        behavior: 'smooth',
      });
    }
    document.getElementsByTagName('html')[0].style.top = top;
    document.getElementsByTagName('html')[0].style.position = position;
    document.getElementsByTagName('html')[0].style.width = width;
  }

  public setPreloadObjectDetail(selectedObject) {
    this.preloadSelectedObjectId = parseInt(selectedObject.id, 10);
  }

  public openObjectDetail(selectedObject: Object) {
    this.selectedObjectTitle = selectedObject.title;
    this.selectedObjectId = parseInt(selectedObject.id, 10);
  }

  private resetScrollBar() {
    setTimeout(() => {
      if (this.scrollContentDiv != null) {
        this.scrollContentDiv.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 100);
  }

  private handleContainerScroll(event: Event): void {
    const scrollContainer = event.target as HTMLDivElement;
    if (scrollContainer != null) {
      const newScrollPosition = scrollContainer.scrollTop;
      const scrollEndArea = scrollContainer.scrollHeight - 600;
      const scrollUp = newScrollPosition < this.currentScrollPosition;
      this.objectToolbarVisible = newScrollPosition < 70
        || (scrollUp && newScrollPosition < scrollEndArea);
      this.currentScrollPosition = newScrollPosition;
    }
  }

  /**
   *
   * @param filterNew
   *
   * const eventData = {
   *  plz: this.plz,
   *  city: this.city,
   *  availableDate: this.availableDate,
   *  livingRoom: this.rangeLivingroom,
   *  price: this.rangePrice,
   *  floor: this.floorLevel,
   *  configurations: this.getSelectedIdOfConfiguration(),
   * };
   *
   */
  @Watch('filter')
  private changeFilter(filterNew) {
    this.objectsFiltered = this.objects
      .filter((object: Object) => {
        let cityValid = true;
        cityValid = !(filterNew.city != null && filterNew.city.length > 2 && !object.ortschaft.includes(filterNew.city));
        const configurationValid = filterNew.configurations
          .every((confFilter) => object.configuration
            .find((confObject) => confObject.id === confFilter) !== undefined);
        const priceValid = filterNew.price[0] <= object.price && filterNew.price[1] >= object.price;
        const roomsValid = filterNew.rooms[0] <= object.rooms && filterNew.rooms[1] >= object.rooms;
        const livingroomValid = filterNew.livingRoom[0] <= object.livingroom
          && filterNew.livingRoom[1] >= object.livingroom;
        return roomsValid && cityValid && configurationValid && priceValid && livingroomValid;
      });
  }

  @Watch('type')
  private loadObjectsByType() {
    const { type } = this;
    this.isLoading = true;
    this.$apollo.query<{entries: Object[]}>({
      query: gql`query {
        entries(section: "Objects", type: "${type}", orderBy: "heroObject DESC") {
          ... on Objects_${type}_Entry {
            id
            slug
            title
            typeHandle
            configuration {
              id
              title
            }
            heroObject
            price
            quadratmeterPreis
            etage
            address
            plz
            rooms
            livingroom
            ortschaft
            images {
              ... on objects_Asset {
                id
                urlPreview: url @transform(width: 600, quality: 60)
              }
            }
            generalInformation {
              ... on generalInformation_verfuegbarkeit_BlockType {
                id
                aufNachfrage
                availability @formatDateTime (format: "Y-m-d")
                __typename
              }
            }
          }
        }
      }
    `,
    }).then((data) => {
      this.isLoading = false;
      if (data.data.entries != null) {
        this.objects = [...data.data.entries];
        this.objectsFiltered = [...data.data.entries];
      }

      if (typeof this.filter === 'object' && this.objects.length === 0) {
        this.$emit('noObjects', true);
      } else {
        this.$emit('noObjects', false);
      }

      const { objectId } = this.$route.params;
      if (objectId) {
        const object = this.objects.find((o) => (o.id === objectId || o.slug === objectId));
        if (object) {
          this.openObjectDetail(object);
        }
      }
    });
  }
}
