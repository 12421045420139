







import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class ObjectDetailGeneralInformationInfo extends Vue {
  @Prop({ type: String })
  public titel!: string;

  @Prop({ type: String })
  public info!: string;
}
