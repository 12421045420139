





















































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class ObjectDetailShortInfos extends Vue {
  @Prop({ type: String })
  public rooms!: string;

  @Prop({ type: Number })
  public etage!: number;

  @Prop({ type: Number })
  public livingroom!: number;

  @Prop({ type: Boolean })
  public showAsList?: boolean;

  @Prop({ type: Boolean })
  public smallIcon?: boolean;
}
