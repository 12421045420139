import { render, staticRenderFns } from "./ObjectDetailShortInfos.vue?vue&type=template&id=5b491d2b&scoped=true&"
import script from "./ObjectDetailShortInfos.vue?vue&type=script&lang=ts&"
export * from "./ObjectDetailShortInfos.vue?vue&type=script&lang=ts&"
import style0 from "./ObjectDetailShortInfos.vue?vue&type=style&index=0&id=5b491d2b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b491d2b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VChip,VCol,VIcon,VList,VListItem,VListItemContent,VListItemIcon,VRow,VTooltip})
