












































































































































































import Vue from 'vue';
import gql from 'graphql-tag';
import { Component, Prop, Watch } from 'vue-property-decorator';
import HereMap from '@/components/reusable/HereMap.vue';
import Configurations from '@/components/content/Configurations.vue';
import ObjectDetailCarousel from '@/components/content/detailObject/ObjectDetailCarousel.vue';
import ObjectDetailCarouselPreview from '@/components/content/detailObject/ObjectDetailCarouselPreview.vue';
import ObjectDetailGeneralInformationAvailable
  from '@/components/content/detailObject/ObjectDetailGeneralInformationAvailable.vue';
import ObjectDetailGeneralInformationCost
  from '@/components/content/detailObject/ObjectDetailGeneralInformationCost.vue';
import ObjectDetailGeneralInformationInfo
  from '@/components/content/detailObject/ObjectDetailGeneralInformationInfo.vue';
import ObjectDetailShortInfos from '@/components/content/detailObject/ObjectDetailShortInfos.vue';
import CallToAction from '@/components/reusable/CallToAction.vue';
import ObjectDetailInteresstingForm from '@/components/content/detailObject/ObjectDetailInteresstingForm.vue';

@Component({
  components: {
    HereMap,
    CallToAction,
    Configurations,
    ObjectDetailCarousel,
    ObjectDetailShortInfos,
    ObjectDetailCarouselPreview,
    ObjectDetailInteresstingForm,
    ObjectDetailGeneralInformationInfo,
    ObjectDetailGeneralInformationCost,
    ObjectDetailGeneralInformationAvailable,
  },
})
export default class ObjectDetail extends Vue {
  private isLoading: boolean = false;

  private loadedType: string = '';

  private dataCleaned: boolean = false;

  public betreffLink: string = '';

  public map: any;

  public carouselIndex: number = 0;

  public object: any = {};

  @Prop({ type: String })
  public height!: string;

  @Prop({ type: Number })
  public id!: number;

  @Prop({ type: Number })
  public preloadId!: number;

  @Prop({ type: String })
  public type!: string;

  created() {
    this.preloadId = -1;
    this.id = -1;
  }

  mounted() {
    this.$store.watch(
      (state, getters) => getters.getPostMessages,
      (postMessages) => {
        const { windowHeight } = postMessages;
        if (Number.isInteger(windowHeight) && windowHeight > 0) {
          const iframe: HTMLIFrameElement | null = document.querySelector('.iframe-contact-form-wrapper');
          if (iframe) {
            iframe.style.height = '';
            iframe.style.height = `${windowHeight + 30}px`;
          }
        }
      },
    );
  }

  @Watch('id')
  public onIdChanged(id: number, oldId: number): void {
    if (id > 0 && id !== oldId) {
      this.loadObjectByIdAndType(id, this.type);
    } else {
      this.isLoading = true;
      this.object = {};
    }
  }

  @Watch('preloadId')
  public onPreloadIdChanged(preloadId: number, oldPreloadId: number): void {
    if (preloadId > 0 && preloadId !== oldPreloadId) {
      this.loadObjectByIdAndType(preloadId, this.type);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public getComponentNameByType(typeName: string) {
    switch (typeName) {
      case 'generalInformation_info_BlockType':
        return 'ObjectDetailGeneralInformationInfo';
      case 'generalInformation_verfuegbarkeit_BlockType':
        return 'ObjectDetailGeneralInformationAvailable';
      case 'generalInformation_costs_BlockType':
        return 'ObjectDetailGeneralInformationCost';
      default:
        return 'ObjectDetailGeneralInformationInfo';
    }
  }

  private loadObjectByIdAndType(id: number, type: string) {
    if (id !== this.object.id || type !== this.loadedType) {
      this.$emit('DataLoaded', false);
      this.isLoading = true;
      this.$apollo.query({
        query: gql`query {
        entries(id: ${id}) {
          ... on Objects_${type}_Entry {
            id
            title
            slug
            configuration {
              id
              title
            }
            etage
            livingroom
            rooms
            price
            quadratmeterPreis
            address
            plz
            ortschaft
            description
            pdf {
              id
              title
              url
            }
            images {
              ... on objects_Asset {
                id
                height
                width
                focalPoint
                urlSlideshowPreview: url @transform(height: 80)
                urlSlideshow: url @transform(width: 1000, quality: 50)
              }
            }
            generalInformation {
              ... on generalInformation_info_BlockType {
                id
                titel
                info
                __typename
              }
              ... on generalInformation_verfuegbarkeit_BlockType {
                id
                aufNachfrage
                availability
                __typename
              }
              ... on generalInformation_costs_BlockType {
                id
                titel
                cost
                __typename
              }
            }
            berater {
              firstName
              lastName
              email
              ... on User {
                telefonnummer
              }
              photo {
                ... on profil_Asset {
                  id
                  url @transform (height: 100, width: 100, mode: "crop", position: "top-center")
                }
              }
            }
            distances {
              ... on distances_distances_BlockType {
                id
                distance
                titel
                typeHandle
              }
            }
          }
        }
      }
    `,
      }).then((data) => {
        this.isLoading = false;
        if (data.data.entries != null) {
          this.object = data.data.entries[0] || {};
          this.resetState();
          this.$router.onReady(() => {
            this.betreffLink = encodeURI(`${process.env.VUE_APP_BASE_URL}${this.$route.path}` || '');
          });
          this.$emit('DataLoaded', true);
        }
      });
    }
  }

  public get iframeHostName(): string {
    return process.env.VUE_APP_CONTACT_FORM || '';
  }

  private resetState() {
    this.carouselIndex = 0;
  }
}
