








































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import store from '@/store';

@Component({})
export default class ObjectDetailCarousel extends Vue {
  private carouselElement;

  private carouselIndex: number = 0;

  public carouselBigModeVar: boolean = false;

  public set carouselBigMode(mode: boolean) {
    if (!this.$vuetify.breakpoint.xs) {
      this.carouselBigModeVar = mode;
    } else {
      this.carouselBigModeVar = true;
    }
  }

  public get carouselBigMode(): boolean {
    return this.carouselBigModeVar;
  }

  @Prop({ type: Number })
  public carouselIndexProp!: number;

  @Prop({ type: Boolean })
  public isLoading!: boolean;

  @Prop({ type: String })
  public height!: string;

  @Prop({ type: Array })
  public slides!: [];

  public carouselHeight: number = 0;

  created() {
    if (!this.carouselElement != null) {
      this.setCarouselElement();
    }
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('keyup', this.handleKeyUp);
    this.handleResize();

    this.carouselBigMode = this.$store.getters.getCarousel;

    store.watch(
      (state, getters) => getters.getCarousel,
      (newValue) => {
        this.carouselBigMode = newValue;
      },
    );
  }

  @Watch('carouselIndexProp')
  private changeSlideIndex(newValue) {
    this.carouselIndex = newValue;
  }

  @Watch('slides')
  private slidesChanged() {
    this.setCarouselElement();
    this.carouselIndex = 0;
  }

  @Watch('carouselBigMode')
  private changeCarouselMode() {
    this.handleResize();
  }

  mounted() {
    this.handleResize();
  }

  destroy() {
    window.removeEventListener('resize', this.handleResize);
  }

  public handleKeyUp(event) {
    if (event.key === 'ArrowRight') {
      this.carouselIndex += 1;
    }

    if (event.key === 'ArrowLeft') {
      this.carouselIndex -= 1;
    }

    if (this.carouselIndex < 0) {
      this.carouselIndex = this.slides.length - 1;
    }

    if (this.slides != null && this.carouselIndex >= this.slides.length) {
      this.carouselIndex = 0;
    }
  }

  public handleResize() {
    if (!this.carouselElement != null) {
      this.setCarouselElement();
    }
    if (this.carouselElement != null && this.slides[this.carouselIndex] != null) {
      const activeImg: {width: number, height: number} = this.slides[this.carouselIndex];
      const imageRation = activeImg.height / activeImg.width;
      const bigImageRatio = this.carouselBigModeVar || this.$vuetify.breakpoint.xs;
      const imgMultiplikator = bigImageRatio ? imageRation : 0.4;
      if (this.carouselElement.clientWidth === 0) {
        this.carouselHeight = 450;
      } else {
        const windowHeight = window.innerHeight - 150;
        const windowWidth = window.innerWidth;
        this.carouselHeight = (windowWidth * imgMultiplikator) > windowHeight ? windowHeight : windowWidth * imgMultiplikator;
      }
    } else {
      this.carouselHeight = 450;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public calculateFocalPoint(focal: number[]): string {
    return focal.map((point) => `${point * 100}%`).join(' ');
  }

  private setCarouselElement() {
    if ('carouselSelector' in this.$refs) {
      const { carouselSelector } = this.$refs;
      if (carouselSelector) {
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const [key, value] of Object.entries(carouselSelector)) {
          if (key === '$el') {
            this.carouselElement = value;
          }
        }
      }
    }
  }
}
