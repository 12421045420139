













import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import 'here-js-api/scripts/mapsjs-core';
import 'here-js-api/scripts/mapsjs-service';
import 'here-js-api/scripts/mapsjs-ui';

interface MapPosition {
  lat: number;
  lng: number;
}

declare const H: any;

@Component({})
export default class HereMap extends Vue {
  private map: any = null;

  private isIntersecting: boolean = false;

  private addedObjects: any[] = [];

  private platform: any;

  private defaultLayers: any;

  @Prop({ type: String })
  public search!: string;

  @Prop({ type: String })
  public lat!: string;

  @Prop({ type: String })
  public lng!: string;

  @Prop({ type: String })
  public width!: string;

  @Prop({ type: String })
  public height!: string;

  created() {
    this.platform = new H.service.Platform({
      apikey: '8L9Ldn6Dvh66fSanviSq9MfwdDRkhZOG-760jfanjcw',
    });
    window.addEventListener('resize', () => {
      if (this.map) {
        this.map.getViewPort().resize();
      }
    });
  }

  private defineMap(pos: MapPosition = { lng: 8.000, lat: 47.3000 }, zoom: number = 8): void {
    this.defaultLayers = this.platform.createDefaultLayers();
    this.map = new H.Map(
      this.$refs.map,
      this.defaultLayers.vector.normal.map,
      {
        zoom,
        center: pos,
      },
    );
    H.ui.UI.createDefault(this.map, this.defaultLayers, 'de-DE');
  }

  private onIntersect(entries) {
    // More information about these options
    // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    this.isIntersecting = entries[0].isIntersecting;
  }

  private onResult(result) {
    if (result.Response.View[0] != null && result.Response.View[0].Result) {
      const locations = result.Response.View[0].Result;
      const lat = locations[0].Location.DisplayPosition.Latitude;
      const lng = locations[0].Location.DisplayPosition.Longitude;
      const position = {
        lat,
        lng,
      };
      const marker = new H.map.Marker(position);
      this.map.addObject(marker);
      this.addedObjects.push(marker);
      this.map.setZoom(14);
      this.map.setCenter(position);
    }
  }

  @Watch('isIntersecting')
  public updateOnSearchResult(newIntersectValue?: boolean): void {
    if (newIntersectValue) {
      if (!this.map) {
        this.defineMap();
      }
      const geocoder = this.platform.getGeocodingService();
      geocoder.geocode({ searchText: this.search }, this.onResult);
    } else {
      this.map.removeObjects(this.addedObjects);
      this.addedObjects = [];
    }
  }
}
