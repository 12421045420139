




















































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import gql from 'graphql-tag';

type RuleType = [() => string];

@Component({})
export default class ObjectsFilter extends Vue {
  public checkboxresult: any = [];

  public floorLevel: number = 0;

  public minEtage: number = 0;

  public showCheckboxes: boolean = true;

  public city: string = '';

  public cityList: string[] = [];

  public minPrice: number = 0;

  public availableDate: string = '';

  public datePickerMenu: boolean = false;

  public minLivingroom: number = 5;

  public maxLivingroom: number = 300;

  public maxEtage: number = 9;

  public stepSizeLivingroom: number = 1;

  public stepSizePrice: number = 10;

  public stepSizeRooms: number = 0.5;

  public minRooms: number = 1;

  public maxRooms: number = 9;

  public maxPrice: number = 3000;

  public isLoading: boolean = true;

  public rangeEtage: number[] = [this.minEtage, this.maxEtage];

  public rangeRooms: number[] = [this.minRooms, this.maxRooms];

  public rangeLivingroom: number[] = [this.minLivingroom, this.maxLivingroom];

  public rangePrice: number[] = [this.minPrice, this.maxPrice];

  public isPanelClosed: boolean = false;

  private configMapper: number[] = [];

  public configurations: any[] = [];

  public selectedConfigurations: any[] = [];

  @Prop({ type: String })
  public type!: string;

  created() {
    this.setCheckboxData();
    this.$store.watch(
      (state, getters) => getters.getConfigurations,
      () => {
        this.setCheckboxData();
      },
    );

    this.isLoading = true;
    const { type } = this;
    this.$apollo.query({
      query: gql`query {
        entries(section: "Objects") {
          ... on Objects_${type}_Entry {
            etage
            livingroom
            rooms
            price
            ortschaft
          }
        }
      }
    `,
    }).then((data) => {
      this.isLoading = false;
      if (data.data.entries != null && data.data.entries.length > 0) {
        const filters = data.data.entries
          .map((entry) => (entry.price != null ? entry : null))
          .filter((entry) => entry != null);

        if (filters[0].price != null) {
          const minPrice = Math.min(...filters.map((a) => a.price));
          let maxPrice = Math.max(...filters.map((a) => a.price));
          if ((maxPrice - minPrice) % this.stepSizePrice !== 0) {
            const stepsize = this.stepSizePrice;
            const baseMulti = Math.floor((maxPrice - minPrice) / stepsize) + 1;
            maxPrice = minPrice + baseMulti * stepsize;
          }
          this.minPrice = minPrice;
          this.maxPrice = maxPrice;
          this.rangePrice = [minPrice, maxPrice];
        }

        if (filters[0].ortschaft != null) {
          this.cityList = [...new Set<string>(filters.map((entry) => entry.ortschaft))];
        }

        if (filters[0].etage != null) {
          this.minEtage = Math.min(...filters.map((a) => a.etage));
          this.maxEtage = Math.max(...filters.map((a) => a.etage));
          this.rangeEtage = [this.minEtage, this.maxEtage];
        }

        if (filters[0].livingroom != null) {
          const roundStepp = 10;
          let minLivingroom: number = Math.min(...filters.map((a) => a.livingroom));
          let maxLivingroom: number = Math.max(...filters.map((a) => a.livingroom));

          if ((maxLivingroom - minLivingroom) % this.stepSizeLivingroom !== 0) {
            const stepsize = this.stepSizeLivingroom;
            const baseMulti = Math.floor((maxLivingroom - minLivingroom) / stepsize) + 1;
            maxLivingroom = minLivingroom + baseMulti * stepsize;
          }

          if (minLivingroom % roundStepp !== 0) {
            minLivingroom -= (minLivingroom % roundStepp);
          }

          if (maxLivingroom % roundStepp !== 0) {
            maxLivingroom += roundStepp - (maxLivingroom % roundStepp);
          }

          this.minLivingroom = minLivingroom;
          this.maxLivingroom = maxLivingroom;
          this.rangeLivingroom = [minLivingroom, maxLivingroom];
        }

        if (filters[0].rooms != null) {
          this.minRooms = Math.min(...filters.map((a) => a.rooms));
          this.maxRooms = Math.max(...filters.map((a) => a.rooms));
          this.rangeRooms = [this.minRooms, this.maxRooms];
        }
      }
    });
  }

  public clickChipFilter() {
    // this.checkboxresult[filter] = !this.checkboxresult[filter];
    this.configMapper.forEach((item, index) => {
      const selectedConfiguration = this.selectedConfigurations.find((innerItem) => innerItem === item);
      this.checkboxresult[index] = selectedConfiguration != null;
    });
    this.fireFilterChangedEvent();
  }

  public fireFilterChangedEvent() {
    const eventData = {
      city: this.city,
      availableDate: this.availableDate,
      livingRoom: this.rangeLivingroom,
      price: this.rangePrice,
      rooms: this.rangeRooms,
      floor: this.floorLevel,
      configurations: this.getSelectedIdOfConfiguration(),
    };

    this.$emit('filterChanged', eventData);
  }

  public toggleExpansionPanel(event): void {
    let clickedElement = event.target;
    if (clickedElement.classList.contains('v-icon')) {
      clickedElement = clickedElement.parentElement.parentElement;
    }
    this.isPanelClosed = clickedElement.classList.contains('v-expansion-panel-header--active');
  }

  public validateMinRange(event: number) {
    if (event < this.minPrice || event > this.maxPrice) {
      this.rangePrice[0] = this.minPrice;
    }
  }

  public validateMaxPrice(event) {
    if (event < this.minPrice || event > this.maxPrice) {
      this.rangePrice[0] = this.maxPrice;
    }
  }

  private get storeConfigurations() {
    return this.$store.state.configurations;
  }

  private setCheckboxData() {
    if (this.storeConfigurations !== this.configurations) {
      this.configurations = this.storeConfigurations;
      this.configMapper = [];
      this.configurations.forEach((config, index) => {
        this.checkboxresult[index] = false;
        this.configMapper[index] = config.id;
      });
    }
  }

  private getSelectedIdOfConfiguration(): number[] {
    return this.checkboxresult
      .map((checkbox, index) => (checkbox ? this.configMapper[index] : -1))
      .filter((result) => result >= 0);
  }
}

