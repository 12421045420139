



















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class ObjectDetailCarouselPreview extends Vue {
  @Prop({ type: Array })
  public slides!: [];
}
