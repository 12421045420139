






































































import Vue from 'vue';
// eslint-disable-next-line no-unused-vars
import axios, { AxiosProxyConfig } from 'axios';
import { Component, Prop } from 'vue-property-decorator';
import { required, email } from 'vuelidate/lib/validators';

@Component({
  validations: {
    salutation: {
      required,
    },
    name: {
      required,
    },
    email: {
      required,
      email,
    },
  },
})
export default class ObjectDetailInteresstingForm extends Vue {
  public salutation: string = '';

  public name: string = '';

  public email: string = '';

  public phone: string = '';

  public text: string = '';

  public formSending: boolean = false;

  @Prop({ type: Boolean })
  public collapsible!: boolean;

  public showForm: boolean = !this.collapsible;

  public sendingError: string = '';

  public get salutationErrors(): string[] {
    const errors: string[] = [];

    if (this.$v.salutation != null && this.$v.salutation.$error && this.$v.salutation.$dirty) {
      errors.push('Bitte selektieren Sie eine Auswahl.');
    }

    return errors;
  }

  public get nameErrors(): string[] {
    const errors: string[] = [];

    if (this.$v.name != null && this.$v.name.$error && this.$v.name.$dirty) {
      errors.push('Geben Sie hier Ihren Namen an.');
    }

    return errors;
  }

  public get emailErrors(): string[] {
    const errors: string[] = [];

    if (this.$v.email != null && this.$v.email.$error && this.$v.email.$dirty) {
      errors.push('Geben Sie Ihre gültige Emailadresse an.');
    }

    return errors;
  }

  public submit(): void {
    this.$v.$touch();
    this.formSending = true;
    if (this.$v.$invalid) {
      this.sendingError = '';
    } else {
      const formData = new FormData();
      formData.append('action', 'contact-form/send');
      formData.append('fromName', 'Laalalal Name');
      formData.append('message', 'Some M se s age');
      formData.append('fromEmail', 'asa@asdf.ch');

      const getData = 'action=contact-form/send&fromName=Laalalal Name&message=Some M se s age&fromEmail=asa@asdf.ch';

      axios.get(
        `${process.env.VUE_APP_GRAPHQL_HTTP}/actions/contact-form/send${getData}`,
        {
          headers: {
            'Access-Control-Allow-Origin': 'http://localhost:8880',
          },
        },
      );
      /**
      // https://stackoverflow.com/questions/43871637/no-access-control-allow-origin-header-is-present-on-the-requested-resource-whe
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${process.env.VUE_APP_GRAPHQL_HTTP}/actions/contact-form/send`);
      // xhr.setRequestHeader('Content-Type', 'multipart/form-data');
      // xhr.setRequestHeader('Content-Type', 'application/json');
      // xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
      xhr.onload = () => {
        if (xhr.status === 200) {
          // console.log(`Something went wrong.  Name is now ${xhr.responseText}`);
        } else if (xhr.status !== 200) {
          // console.log(`Request failed.  Returned status of ${xhr.status}`);
        }
      };

      xhr.send(formData);
  */
    }
  }
}
