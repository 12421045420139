










import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class ObjectDetailGeneralInformationAvailable extends Vue {
  @Prop({ type: String })
  public titel!: string;

  @Prop({ type: Boolean })
  public aufNachfrage!: boolean;

  @Prop({ type: String })
  public availability!: string;
}
