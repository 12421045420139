



















































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Configurations from '@/components/content/Configurations.vue';
import ObjectDetailShortInfos from '@/components/content/detailObject/ObjectDetailShortInfos.vue';

@Component({
  components: {
    Configurations,
    ObjectDetailShortInfos,
  },
})
export default class ObjectCard extends Vue {
  private cardMouseLeve: boolean = false;

  @Prop({ type: Number, default: 0 })
  public dataLoadedObjectId!: number;

  @Prop({ type: Boolean, default: false })
  public isLoaded!: boolean;

  @Prop({ type: Object })
  public object!: any;

  @Prop({ type: Boolean })
  public isHeroObject!: boolean;

  public elevationDefaultValue: number = 3;

  public elevationValue: number = this.elevationDefaultValue;

  public get objectIsLoading(): boolean {
    return this.dataLoadedObjectId === parseInt(this.object.id, 10) && !this.isLoaded;
  }

  public cardEntered(object) {
    setTimeout(() => {
      if (!this.cardMouseLeve) {
        this.$emit('preloadCardClicked', object);
      } else {
        this.cardMouseLeve = false;
      }
    }, 400);
  }

  // eslint-disable-next-line class-methods-use-this
  public get cardHeight(): string {
    const bp = this.$vuetify.breakpoint;

    if (bp.xsOnly) {
      return '70vw';
    }

    if (bp.smOnly) {
      return '35vw';
    }

    if (bp.mdOnly) {
      return '220px';
    }
    return '270px';
  }

  public cardNotPreload() {
    this.cardMouseLeve = true;
  }

  public getElevationValue(hover: boolean): number {
    if (this.elevationValue === this.elevationDefaultValue) {
      if (hover) {
        return this.elevationValue;
      }
      return 0;
    }
    return this.elevationValue;
  }

  public cardClicked(object, newElevationValue) {
    this.raiseElevationValue(newElevationValue);
    this.$router.push({ name: `${object.typeHandle.toLowerCase()}-detail`, params: { objectId: object.slug } });
    this.$emit('cardClicked', object);
  }

  private raiseElevationValue(newElevationValue) {
    this.elevationValue = newElevationValue;
    setTimeout(() => {
      this.elevationValue = this.elevationDefaultValue;
    }, 1000);
  }

  public mailtoHref(objectName, slug) {
    const content = encodeURI(`${this.$store.getters.getSettings.shareText} Zum Angebot: \n \n${process.env.VUE_APP_BASE_URL}${this.$route.path}/${slug}`);
    return `mailto:?subject=${objectName}&body=${content}`;
  }

  public whatsappHref(objectName, slug) {
    const content = encodeURI(`${this.$store.getters.getSettings.shareText}: "${objectName}" Zum Angebot: \n \n${process.env.VUE_APP_BASE_URL}${this.$route.path}/${slug}`);
    return `whatsapp://send?text=${content}`;
  }
}
