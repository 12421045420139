
































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

interface Consultant {
  firstName: string,
  lastName: string,
  email: string,
  simpleDescription: string,
  telefonnummer: string,
  photo: {
    id: number,
    url: string,
  }
}

@Component({})
export default class CallToAction extends Vue {
  @Prop({ type: Object, required: true })
  public consultant!: Consultant;

  @Prop({ type: String })
  public objectId!: string;

  @Prop({ type: String, default: '' })
  public requestText!: string;

  private emailShareBody: string = `Sehr geehrter Herr ${this.consultant.lastName}`;

  created() {
    this.consultant.telefonnummer = this.consultant.telefonnummer != null ? this.consultant.telefonnummer : '';
  }

  public mailtoHref(objectName) {
    return `mailto:${this.consultant ? this.consultant.email : ''}?subject=${objectName}&body=${this.emailShareBody}`;
  }
}
