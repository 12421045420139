

















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

interface Configuration {
  key: number;
  icon: string;
  iconName: string;
  iconSettings: {disabled: boolean};
  toolTipSettings: {
    top?: boolean;
    right?: boolean;
    bottom?: boolean;
    left?: boolean;
  };
  toolTipNudgeSetting: {
    top?: boolean;
    right?: boolean;
    bottom?: boolean;
    left?: boolean;
  }
}

@Component({})
export default class Configurations extends Vue {
  private defaultToolTipSettings = {
    top: true,
  };

  private defaultIconSettings = {
    color: 'primary',
  };

  private get storeConfigurations() {
    return this.$store.state.configurations;
  }

  @Prop(Boolean)
  public showAsList?: boolean;

  @Prop(Boolean)
  public smallIcon?: boolean;

  @Prop(String)
  public iconColor?: string;

  @Prop(Boolean)
  public showInactiveIcons?: boolean;

  @Prop(String)
  public toolTipPosition: string | undefined;

  @Prop(Array)
  public visibleIcons: {title: string}[] | undefined;

  public configurations: Configuration[] = [];

  created() {
    if (this.storeConfigurations != null) {
      const configurations = this.storeConfigurations;
      let toolTipPosition = {};
      if (this.toolTipPosition != null) {
        if (this.toolTipPosition.toLowerCase() === 'top') {
          toolTipPosition = { top: true };
        }
        if (this.toolTipPosition.toLowerCase() === 'right') {
          toolTipPosition = { right: true };
        }
        if (this.toolTipPosition.toLowerCase() === 'bottom') {
          toolTipPosition = { bottom: true };
        }
        if (this.toolTipPosition.toLowerCase() === 'left') {
          toolTipPosition = { left: true };
        }
      }

      let visibleIcon;
      if (this.visibleIcons) {
        visibleIcon = this.visibleIcons.map((icon) => icon.title);
      } else {
        visibleIcon = [''];
      }

      // eslint-disable-next-line arrow-body-style
      this.configurations = configurations.map((conf) => {
        return {
          key: conf.id,
          iconName: conf.title,
          icon: conf.iconName,
          iconTitle: conf.title,
          iconDesc: conf.simpleDescription,
          iconSettings: {
            ...this.defaultIconSettings,
            ...{
              color: this.iconColor,
              disabled: !visibleIcon.includes(conf.title),
            },
          },
          toolTipSettings: { ...this.defaultToolTipSettings, ...toolTipPosition },
        };
      });

      if (this.showInactiveIcons != null && !this.showInactiveIcons) {
        this.configurations = this.configurations.filter((conf) => {
          if (this.visibleIcons) {
            return visibleIcon.includes(conf.iconName);
          }
          return false;
        });
      }
    }
  }
}

